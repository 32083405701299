import React, { Component } from 'react'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { MapInteractionCSS } from 'react-map-interaction';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import './lightbox.css'

class LightBox extends Component {
    targetRef = React.createRef();
    targetElement = null;

    state = {
        modalOpen: false,
        activeImage: null,
        scale: 1,
        translation: { x: 0, y: 0 }
    }

    keyHandling = (e) => {
        if (e.keyCode === 37) {
            this.prev();
        }
        else if (e.keyCode === 39) {
            this.next();
        }
        else if (e.keyCode === 27) {
            this.close();
        }
    }

    componentDidMount() {
        this.targetElement = this.targetRef.current;
        window.addEventListener("keyup", this.keyHandling);
    }

    componentWillUnmount() {
        clearAllBodyScrollLocks();
        window.removeEventListener("keyup", this.keyHandling);
    }

    open = (image) => {
        this.setState({
            modalOpen: true,
            activeImage: image,
            scale: 1,
            translation: { x: 0, y: 0 }
        })

        disableBodyScroll(this.targetElement);
    }

    close = (event) => {
        this.setState({
            modalOpen: false
        });

        enableBodyScroll(this.targetElement);
    }

    next = () => {
        if (this.state.activeImage) {
            var activeIndex = this.state.activeImage.index;
            if (activeIndex === this.props.images.length - 1) {
                activeIndex = 0;
            }
            else {
                activeIndex++;
            }
            this.setState({
                activeImage: this.props.images[activeIndex],
                scale: 1,
                translation: { x: 0, y: 0 }
            })
        }
    }

    prev = () => {
        if (this.state.activeImage) {
            var activeIndex = this.state.activeImage.index;
            if (activeIndex === 0) {
                activeIndex = this.props.images.length - 1;
            }
            else {
                activeIndex--;
            }
            this.setState({
                activeImage: this.props.images[activeIndex],
                scale: 1,
                translation: { x: 0, y: 0 }
            })
        }
    }

    render() {
        return (
            <div ref={this.targetRef} style={{ userSelect: 'none' }}>
                {this.props.images.map((image, index) => {
                    return <img onClick={() => this.open(image)} className="thumbnail" src={image.src} alt={image.title} height={this.props.thumbnailHeight} key={"image_" + index} />
                })}

                {
                    this.state.modalOpen ?
                        <div className="fullscreen-modal" >
                            <div className="header">
                                <div className="title" onClick={this.close}>
                                    Bild {this.state.activeImage.index + 1}/{this.props.images.length} - {this.state.activeImage.title}
                                </div>
                                <div className="closeIcon" onClick={this.close}>
                                    <FontAwesomeIcon icon={faTimesCircle} />
                                </div>
                            </div>
                            <div className="content">
                                <div className="img-holder">
                                    <div className="prev" onClick={this.prev}><FontAwesomeIcon icon={faChevronLeft} /></div>
                                    <MapInteractionCSS
                                        minScale={0.3}
                                        scale={this.state.scale}
                                        translation={this.state.translation}
                                        onChange={({ scale, translation }) => this.setState({ scale, translation })}
                                    >
                                        <img src={this.state.activeImage.src} alt={this.state.activeImage.title} />
                                    </MapInteractionCSS>
                                    <div className="next" onClick={this.next}><FontAwesomeIcon icon={faChevronRight} /></div>
                                </div>
                            </div>
                            <div className="footer" onClick={this.close}>
                                {this.state.activeImage.description}
                            </div>
                        </div>
                        : null
                }
            </div>
        )

    }
}

export default LightBox;